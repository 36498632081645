<template>
  <v-autocomplete
    v-model="value"
    :items="options"
    class="w-85 mr-1"
    autofocus
    dense
    multiple
    small-chips
    clearable
    @change="handleSelectionsChanged"
    :label="$t('Button.SelectUsers')"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('EmptyData.NoResultsMatch') }} <b>{{ keySearch }}</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <!-- Display option to select -->
    <template v-slot:item="{ item }">
      <div class="d-flex align-center pt-2 pb-2 w-100">
        <v-icon
          v-if="value.includes(item.userID)"
          color="green darken-2"
          class="mr-3"
          >mdi-check-circle</v-icon
        >
        <v-icon v-else class="mr-3">mdi-checkbox-blank-circle-outline</v-icon>
        <v-avatar size="25" class="mr-3" color="indigo">
          <template v-if="item.avatar != ''">
            <img :src="item.avatar" :alt="item.fullName" />
          </template>
          <template v-else>
            <span class="white--text infoName">
              {{ getNameInnitial(item.fullName) }}
            </span>
          </template>
        </v-avatar>
        <div class="userInfo">
          <div class="userInfo__name">
            <span>{{ item.fullName }}</span>
          </div>
          <div class="userInfo__role">
            <span>{{ item.userTypeName }}</span>
          </div>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import AppConstant from "@/plugins/constant";
import UserBusiness from "@/services/user";
import { getNameInnitial } from "@/plugins/helper";

export default {
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      options: [],
      keySearch: "",
    };
  },
  created() {
    this.getListUserOptions();
  },
  methods: {
    getNameInnitial,
    async getListUserOptions() {
      let { clinicID } = this.$store.getters.userData;
      var result = await UserBusiness.searchUser(
        clinicID,
        AppConstant.StatusCodes.Active,
        this.keySearch,
        1,
        1000
      );
      if (result.error) {
        this.$toast.error("Can not retrieve user list data!");
        return;
      }
      this.options = result.items.map((i) => ({
        ...i,
        value: i.userID,
        text: i.fullName,
      }));
    },
    handleSelectionsChanged() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.w-15 {
  width: 25%;
}
.w-85 {
  width: 75%;
}
.userInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  .userInfo__name {
    display: block;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    color: #3c4043;
  }
  .userInfo__role {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>