import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'

var httpClient = new HttpClient(ApiGateway.Hr);

const prefix = '/Brand/User';
export default {
  searchUser: (clinicID, statusID, keySearch, page = 1, limit = 10) => {
    var query = {
      clinicID,
      statusID,
      keySearch
    }

    var header = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit
    }
    return httpClient.get(`${prefix}/Search`, query, header);
  }
}