import styled from 'vue-styled-components';

export const ItemEventBooking = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 2px;
    .itemEvent__name {
        display: flex;
        align-items: center;
        width: 100%;
        b {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            display: inline-block;
        }     
    }
    .v-event-draggable {
        padding-left: 6px;
    }
    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }
    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 8px;
        cursor: ns-resize;
        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 8px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }
        &:hover::after {
            display: block;
        }
    }
    .timed {
        p {
            margin: 0px;
            padding: 0px;
        }
    }
`;


export const CalendarContent = styled.div`
    display: block;
    height: auto;
    .v-current-time {
        height: 2px;
        background-color: #ea4335;
        position: absolute;
        left: -1px;
        right: 0;
        pointer-events: none;

        &.first::before {
            content: '';
            position: absolute;
            background-color: #ea4335;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-top: -5px;
            margin-left: -6.5px;
        }
    }

    @media only screen and (max-width: 1980px) {
        height: 800px;
    }
   
    @media only screen and (max-width: 1440px) {
        height: 800px;
    };

    @media only screen and (max-width: 1024px) {
        height: 750px;
    };

    @media only screen and (max-width: 600px) {
        height: 100%;
    };
`;

export const CardEventDetail = styled('div', { bgColor: String })`
    width: 300px;
    padding: 5px 8px;
    background-color: ${props => props.bgColor ? props.bgColor : '#0052cc'};
    color: #ffffff;
    display: block;
`;
