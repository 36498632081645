import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Hr);

const prefix = '/Event';

export default {
  getByID: (eventID) => httpClient.get(`${prefix}/Get/${eventID}`, {}),
  searchEvent: (from, to) => httpClient.get(`${prefix}/Search`, { from, to }),
  searchByUserIDs: (userIDs = [], from, to) => httpClient.post(`${prefix}/SearchByUserIDs`, { from, to }, userIDs),
  createEvent: (bodyReq) => httpClient.post(`${prefix}/Create`, {}, bodyReq),
  updateEvent: (eventID, bodyReq) => httpClient.put(`${prefix}/Update/${eventID}`, {}, bodyReq),
  deleteEvent: (eventID) => httpClient.delete(`${prefix}/Delete/${eventID}`, {}),
}