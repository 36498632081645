import styled from 'vue-styled-components';

export const BookingEventContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    .bookingEvent__title {
      width: 100%;
      padding: 2px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bookingEvent__title__content {
        font-size: 18px;
        font-weight: 450px;
      }
      .icon__action {
        cursor: pointer;
      }
    }
    .bookingEvent__content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item {
          margin: 2px 0;
          width: 85%;
          display: block;
          word-wrap: break-word;
          box-sizing: border-box;
        }
        .bookingEvent__content__time {
         
          margin: 8px 0px;
          font-weight: 400;
        }
    }
`;

const propsFormRoster = {
  hoverContent: Boolean,
  paddingTopIcon: Boolean
}
export const FormRoster = styled('div', propsFormRoster)`
    padding: 5px;
    margin: 0;
    height: auto;
`;

export const FormRosterItemInput = styled('div')`
  display: flex;
  margin: 5px 0;
  padding: 3px;
`;

export const FormRosterItemInputIcon = styled('div', { paddingTopIcon: Boolean })`
  height: 100%;
  display: flex;
  width: 8%;
  justify-content: center;
  align-items: center;
  .v-icon {
    cursor: pointer;
  }
`;

export const FormRosterItemInputContent = styled('div', { hoverContent: Boolean })`
  height: auto;
  align-items: center;
  width: 92%;
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  
  .chipTag__categoryEvent { 
    margin: 3px;
    cursor: pointer;
    font-weight: 450;
    border: 2px solid transparant;
    color: white;
  }
  .chipTag__categoryEvent__active {
    border: 2px solid black;
  }
  .notePresent {
    cursor: pointer;
    font-size: 14px;
    font-weight: 470;
    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    background-color: ${props => props.hoverContent ? '#f1f3f4' : ''};
    cursor: ${props => props.hoverContent ? 'pointer' : ''}
  }
`;

export const RosterTimeBox = styled('div')`
  display: flex;
  flex-direction: column;
  .RosterTimeBox__Time {
    width: 100%;
    display: flex;
    font-family: Roboto, Arial, sans-serif;
    line-height: 20px;
    font-weight: 470;
    font-size: 14px;
    .RosterTimeBox__Time__Date {
      height: 100%;
      .RosterTimeBox__Time__DateBox {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .RosterTimeBox__Time__Hour {
      height: 100%;
      .hourItem {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .RosterTimeBox__AnnounceTime {
    
  }
`;

export const DayOfWeek = styled('div', { active: Boolean })`
  padding: 4px;
  color: white;
  font-weight: bold;
  background-color: ${props => props.active ? '#053a61' : '#007bff'};
  cursor: pointer;
  width: 15%;
  text-align: center;
  position: relative;

  label {
    cursor: pointer;
  }
  .borderRight {
    position: absolute;
    top: 25%;
    height: 50%;
    width: 2px;
    background: white;
    right: 0;
  }
  &:hover {
    background-color: #a4c7f7;
  }
`;