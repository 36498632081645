<template>
  <v-dialog
    eager
    v-model="isShowPopup"
    max-width="820px"
    class="dialog-confirm"
  >
    <v-form ref="RosterForm">
      <v-card>
        <v-card-title> {{ $t('Roster.EventInformation') }} </v-card-title>
        <v-card-text>
          <FormRoster>
            <!-- Title -->
            <FormRosterItemInput>
              <FormRosterItemInputIcon>
                <v-icon> mdi-bookmark-plus-outline </v-icon>
              </FormRosterItemInputIcon>
              <FormRosterItemInputContent>
                <v-text-field
                  dense
                  :placeholder="$t('Roster.TitleEvent')"
                  v-model="data.title"
                  :rules="rules.title"
                ></v-text-field>
              </FormRosterItemInputContent>
            </FormRosterItemInput>
            <!-- Category -->
            <FormRosterItemInput class="mt-2 mb-4">
              <FormRosterItemInputIcon></FormRosterItemInputIcon>
              <FormRosterItemInputContent>
                <v-chip
                  class="chipTag__categoryEvent"
                  v-for="(item, index) in categoryOptions"
                  :key="index"
                  label
                  small
                  :class="{
                    chipTag__categoryEvent__active: item.id == data.categoryID,
                  }"
                  :style="{
                    'background-color': item.bgColor,
                  }"
                  @click="choseEventCategory(item.id)"
                >
                  {{ item.text }}
                </v-chip>
              </FormRosterItemInputContent>
            </FormRosterItemInput>
            <!-- Description -->
            <FormRosterItemInput>
              <FormRosterItemInputIcon>
                <v-icon> mdi-pencil-outline </v-icon>
              </FormRosterItemInputIcon>
              <FormRosterItemInputContent>
                <v-container style="height: 220px" class="ma-0 pa-0 mt-2">
                  <quillEditor
                    v-model="data.description"
                    :rules="rules.title"
                    style="height: 150px"
                  />
                </v-container>
              </FormRosterItemInputContent>
            </FormRosterItemInput>
            <!-- Time Zone -->
            <FormRosterItemInput>
              <FormRosterItemInputIcon>
                <v-icon class="mt-2"> mdi-clock-outline </v-icon>
              </FormRosterItemInputIcon>
              <FormRosterItemInputContent :hoverContent="true" class="pa-4">
                <TimeZone v-model="data" ref="TimeZone" />
              </FormRosterItemInputContent>
            </FormRosterItemInput>
            <!-- Invite Member -->
            <FormRosterItemInput>
              <FormRosterItemInputIcon>
                <v-icon> mdi-account-group-outline </v-icon>
              </FormRosterItemInputIcon>
              <FormRosterItemInputContent>
                <SelectUserOptions
                  v-show="showAttendees"
                  v-model="data.attendeeIDs"
                />
                <v-btn
                  v-show="!showAttendees"
                  @click="showAttendees = true"
                  dense
                  link
                  small
                  class="elevation-0 mt-1"
                >
                  Guests ({{ data.attendeeIDs.length }})
                </v-btn>
              </FormRosterItemInputContent>
            </FormRosterItemInput>
          </FormRoster>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text pl-5 pr-5 mr-10"
            v-if="eventID"
            color="deep-orange"
            @click="deleteEvent"
          >
            Delete Event
          </v-btn>
          <v-btn class="pl-10 pr-10" color="primary" @click="saveEvent">
            Save Event
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <DialogConfirm ref="DialogConfirm" />
  </v-dialog>
</template>

<script>
import { EventCategory, DefaultCalendarEventDto } from "@/plugins/constant";
import {
  FormRoster,
  FormRosterItemInput,
  FormRosterItemInputIcon,
  FormRosterItemInputContent,
} from "./css/style";

import DialogConfirm from "@/components/DialogConfirm";
import SelectUserOptions from "./roster/SelectBoxUser";
import TimeZone from "./roster/TimeZone";
import EventService from "@/services/event";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "RegisterRosterComponent",
  components: {
    quillEditor,
    FormRoster,
    FormRosterItemInput,
    FormRosterItemInputIcon,
    FormRosterItemInputContent,
    SelectUserOptions,
    TimeZone,
    DialogConfirm,
  },
  created() {},
  data: () => ({
    categoryOptions: EventCategory,
    showAttendees: false,
    isShowPopup: false,
    eventID: null,
    data: DefaultCalendarEventDto,
    rules: {
      title: [
        (v) => !!v || "Title is required!",
        (v) => (v && v.length >= 3) || "Title must be more than 3 characters!",
      ],
    },
  }),
  methods: {
    openPopupCreate() {
      this.cleanPopupOptions();
      this.$refs.TimeZone.resetOptions();
      this.$refs.TimeZone.forceUpdateModelValue(this.data);
      this.isShowPopup = true;
    },
    async openPopupUpdate(eventID) {
      this.cleanPopupOptions();
      this.$refs.TimeZone.resetOptions();
      this.isShowPopup = true;
      this.eventID = eventID;
      await this.renderEventDetail();
      this.$refs.TimeZone.forceUpdateModelValue(this.data);
    },
    async renderEventDetail() {
      if (!this.eventID) return;
      var result = await EventService.getByID(this.eventID);
      if (!result || result.error) {
        this.$toast.error("Cannot get event detail. Please try again later.");
        return;
      }
      result.attendeeIDs = [];
      if (result.attendees) {
        result.attendeeIDs = result.attendees.map((at) => at.attendeeID);
      }
      this.data = result;
      this.$forceUpdate();
    },
    async saveEvent() {
      if (!this.$refs.RosterForm.validate()) return;
      var result;
      if (this.eventID) {
        result = await EventService.updateEvent(this.eventID, this.data);
      } else {
        result = await EventService.createEvent(this.data);
      }
      if (!result || result.error) {
        this.$toast.error(
          "Cannot save event information. Please try again later!"
        );
        return;
      }
      this.$toast.success("Save event information successfully.");
      this.$emit("save-event-success", {
        success: true,
        msg: "Add or update Roster success!",
      });
      this.isShowPopup = false;
    },
    async deleteEvent() {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var result = await EventService.deleteEvent(this.eventID);
      if (!result || result.error) {
        this.$toast.error("Cannot delete this event. Please try again later!");
        return;
      }
      this.$toast.success("Delete event information successfully.");
      this.$emit("save-event-success", {
        success: true,
        msg: "Delete Roster success!",
      });
      this.isShowPopup = false;
    },
    choseEventCategory(categoryID) {
      this.data.categoryID = categoryID;
    },
    cleanPopupOptions() {
      this.showAttendees = false;
      this.eventID = null;
      this.data = DefaultCalendarEventDto;
    },
  },
};
</script>

<style lang="scss">
.v-small-dialog__content {
  padding: 0px;
}
.ql-editor {
  color: black;
}
</style>